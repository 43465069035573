* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  height: 100% !important;
  width: 100%;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-family: 'Nunito', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', -apple-system, BlinkMacSystemFont,
  sans-serif;
}

#root {
  height: 100%;
  min-height: 100%;
  background: #fafafa;
}

a {
  text-decoration: none;
  color: rgb(19, 151, 241);
}